<template>
  <div class="box" v-loading.fullscreen.lock="loading">
    <img
      class="m_tb_20 pointer"
      :src="banner"
      alt=""
      width="100%"
      @click="toUploadWorks_click"
    />
    <el-breadcrumb class="m_b_10" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{
          name: 'regionResource',
          params: { classify: '1555104854509219841', name: '劳动教育' },
        }"
      >
        劳动教育
      </el-breadcrumb-item>
      <el-breadcrumb-item>实践助手</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bg_fff br_5 m_b_20 fs_16">
      <div class="p_in_20">
        <input
          type="radio"
          name="type"
          id="course"
          value="1603289215070707714"
          v-model="selected"
        />
        <label
          class="m_r_20 pointer"
          for="course"
          @click="select_click('1603289215070707714')"
        >
          精品微课
        </label>
        <input
          type="radio"
          name="type"
          id="achievement"
          value="1603289185270177793"
          v-model="selected"
        />
        <label
          class="pointer m_r_20"
          for="achievement"
          @click="select_click('1603289185270177793')"
        >
          成果展示
        </label>
        <input
          type="radio"
          name="type"
          id="laborWeek"
          value="1610195614761496578"
          v-model="selected"
        />
        <label
          class="pointer"
          for="laborWeek"
          @click="select_click('1610195614761496578')"
        >
          劳动周资源专享
        </label>
        <input
                type="radio"
                name="type"
                id="jnds"
                value="1610195614761496579"
                v-model="selected"
        />
        <label
                class="pointer"
                for="jnds"
                @click="select_click('1610195614761496579')"
        >
          学生技能大赛
        </label>
      </div>
      <template>
        <div
          class="p_in_20"
          v-for="(parentItem, parentIndex) in subdirectoryData"
          :key="parentItem.id"
        >
          <span
            class="label"
            :class="{ selected: selectedChild[parentIndex] == children.id }"
            v-for="(children, childrenIndex) in parentItem"
            :key="children.id"
            @click="subdirectory_click(parentIndex, childrenIndex, children.id)"
          >
            {{ children.label }}
          </span>
        </div>
      </template>
    </div>
    <div class="flex_row_wrap" v-if="courseList.length > 0">
      <template v-if="selected === '1603289215070707714'">
        <div
          class="course bg_fff br_10 pointer"
          v-for="item in courseList"
          :key="item.id"
          @click="toDetail_click(item)"
        >
          <div
            style="
              width: 100%;
              height: 164px;
              padding: 0;
              overflow: hidden;
              border-radius: 10px 10px 0 0;
            "
          >
            <img
              :src="item.coverUrl"
              :alt="item.name"
              width="100%"
              height="100%"
              style="object-fit: cover"
            />
          </div>
          <div class="fs_18 c_333">{{ item.name }}</div>
          <div class="fs_12 c_666" v-show="item.info.period">
            学段：{{ item.info["period"] }}
          </div>
          <div class="tag">
            <i :class="iconJSON[item.type]"></i>
            {{ typeJSON[item.type] }}
          </div>
        </div>
      </template>
      <template v-else-if="selected === '1603289185270177793'">
        <div
          class="course bg_fff br_10 pointer"
          v-for="item in courseList"
          :key="item.id"
          @click="toActivityDetail_click(item)"
        >
          <img class="cover-img" :src="item.coverUrl" alt="" />
          <div class="fs_18 c_333 flex_row">
            <div
              class="text_hidden"
              style="width: 200px; display: inline-block"
            >
              {{ item.remarks1 ? item.remarks1 : item.name }}
            </div>
            <div class="fs_14" style="display: inline-block; color: #12c294">
              <i class="el-icon-user" style="margin-top: 6px"></i>
              {{ item.info.student }}
            </div>
          </div>
          <div class="fs_12 c_666">
            <span>{{ item.info.school }}</span>
          </div>
          <div class="fs_12 c_666">
            <span>{{ item.info.activity }}</span>
          </div>
          <div class="tag">
            <i :class="iconJSON[item.type]"></i>
            {{ typeJSON[item.type] }}
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="course bg_fff br_10 pointer"
          v-for="item in courseList"
          :key="item.id"
          @click="toDetail_click(item)"
        >
          <img class="cover-img" :src="item.coverUrl" alt="" />
          <div class="fs_18 c_333">{{ item.name }}</div>
          <div class="fs_12 c_666" v-show="item.info.student">
            <span class="m_r_20">{{ item.info.student }}同学</span>
            <span>{{ item.info.school }}</span>
          </div>
          <div class="tag">
            <i :class="iconJSON[item.type]"></i>
            {{ typeJSON[item.type] }}
          </div>
        </div>
      </template>
    </div>
    <el-empty v-else></el-empty>
    <!-- &&{"student":"王子钦","school":"成都石室双楠实验学校双楠小区初中部","activity":"武侯区中小学“云上劳动周”"}&& -->
    <el-pagination
      style="text-align: center; margin-top: 10px"
      hide-on-single-page
      :page-size="12"
      :current-page="params.current"
      @current-change="current_change"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import course from "assets/image/Group 651.png";
import banner from "assets/image/Group 640.png";
import { getResourceList } from "@/api/resourceCenter";
import { htmlUnEscape } from "utils/utils";
import { sessionGet } from "utils/local";
import {
  getPhoneEncrypt,
  getRegionalSubClassifyById,
  getLeftClassifyTree,
} from "../../../../../../api/resourceCenter";

export default {
  name: "PracticeAssistant",
  data() {
    return {
      course,
      banner,
      selected: "1603289215070707714",
      params: {
        contentsId: "",
        current: 1,
        size: 12,
        notShowEnclosure: 1,
      },
      laborWeekSelected: "",
      courseList: [],
      total: 0,
      loading: false,
      iconJSON: {
        1: "el-icon-document",
        2: "el-icon-video-play",
        3: "el-icon-video-play",
        4: "el-icon-picture-outline",
      },
      typeJSON: {
        1: "文档",
        2: "音频",
        3: "视频",
        4: "图片",
      },
      // 子目录
      subdirectoryData: [],
      // 选中的子目录
      selectedChild: [],
    };
  },
  watch: {
    selected: {
      handler(val) {
        if (val === "1610195614761496578") {
          getRegionalSubClassifyById({ id: val, type: 2 }).then((res) => {
            this.laborWeekClassify = res.data;
          });
        } else {
          this.laborWeekClassify = [];
        }
      },
    },
    laborWeekSelected: {
      handler(val) {
        this.getResource(val);
      },
    },
  },
  async mounted() {
    let type = this.$route.query.type;
    let params = this.$route.params;
    if (type)
      this.selected =
        type === "course" ? "1603289215070707714" : type === "achievement" ? "1603289185270177793" : type === "laborWeek" ? "1610195614761496578" : "1610195614761496579";

    await this.getSubdirectory();
    if (params.current) {
      await this.getResource(this.selectedChild.at(-1));
      this.selectedChild[this.selectedChild.length - 1] = params.selected;
      this.params.current = params.current ? Number(params.current) : 1;
    }
    await this.getResource(this.selectedChild.at(-1));
  },
  methods: {
    async select_click(id) {
      this.laborWeekSelected = "";
      this.selected = id;
      this.params.current = 1;
      this.subdirectoryData = [];
      this.selectedChild = [];
      await this.getSubdirectory();
      await this.getResource(this.selectedChild.at(-1));
    },
    async getResource(id) {
      this.loading = true;
      this.params.contentsId = id ? id : this.selected;
      await getResourceList(this.params).then((res) => {
        this.courseList = res.data.records.map((item) => {
          return {
            name: item.name,
            coverUrl: item.coverUrl,
            introduce: item.introduce.split("&&")[0],
            info: item.introduce.split("&&")[1]
              ? JSON.parse(htmlUnEscape(item.introduce.split("&&")[1]))
              : {},
            id: item.id,
            resourcesUrl: item.resourcesUrl,
            browseNumber: item.browseNumber,
            type: item.type,
            remarks1: item.remarks1,
          };
        });
        this.total = res.data.total;
      });
      this.loading = false;
    },
    async current_change(current) {
      this.params.current = current;
      await this.getResource(this.selectedChild.at(-1));
    },
    // 精品微课、劳动周详情
    toDetail_click(data) {
      let childType = "course";
      if (this.selected == 1603289215070707714) {
        childType = "course";
      } else if (this.selected == 1610195614761496578) {
        childType = "labour";
      }
      this.$router.push({
        path: "/resource/practiceAssistantDetail",
        query: {
          id: data.id,
          type: 1,
          current: this.params.current,
          selected: this.selectedChild.at(-1),
          childType,
        },
      });
    },
    toUploadWorks_click() {
      let userInfo = sessionGet("userInfo");
      if (!userInfo) return this.$message.warning("请登录");
      getPhoneEncrypt({ id: userInfo.user_id }).then((res) => {
        if (res.code !== 200) return this.$message.error(res.msg);
        window.open(
          `https://cdwh.wuhousmartedu.com/portalJump/toActivityGuide/${res.data}`
        );
      });
    },
    // 获取子目录
    async getSubdirectory() {
      await getLeftClassifyTree({ id: this.selected }).then((res) => {
        this.processingData(res.data);
      });
    },
    // 处理子目录数据
    processingData(data) {
      if (data.length > 0) {
        this.selectedChild.push(data[0].id);
        this.subdirectoryData.push(data);
        if (data[0].children.length > 0) {
          this.processingData(data[0].children);
        }
      }
    },
    // 点击子目录
    async subdirectory_click(parentIndex, childrenIndex, id) {
      this.params.current = 1;
      this.selectedChild[parentIndex] = id;
      this.subdirectoryData = this.subdirectoryData.splice(0, parentIndex + 1);
      this.selectedChild = this.selectedChild.splice(0, parentIndex + 1);
      let data = this.subdirectoryData[parentIndex][childrenIndex];
      if (data.children.length > 0) {
        this.processingData(data.children);
      }
      await this.getResource(this.selectedChild.at(-1));
      this.$forceUpdate();
    },
    // 成果展示详情
    toActivityDetail_click(data) {
      this.$router.push({
        path: "/resource/activityDetail",
        query: {
          id: data.id,
          type: 1,
          current: this.params.current,
          selected: this.selectedChild.at(-1),
        },
      });
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 0 auto;
}

input {
  display: none;
}

label {
  padding: 8px 20px;
  border-radius: 30px;
  background-color: rgba(18, 194, 148, 0.05);
}

input[type="radio"]:checked + label {
  color: #fff;
  background: linear-gradient(180deg, #64f4cf, #12c294 100%);
}

.label {
  padding: 8px 20px;
  border-radius: 30px;
  background-color: rgba(18, 194, 148, 0.05);
  height: 21px;
  line-height: 21px;
  margin-right: 20px;
  cursor: pointer;
}
.selected {
  color: #fff;
  background: linear-gradient(180deg, #64f4cf, #12c294 100%);
}

.course {
  width: 290px;
  margin-right: 13px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.course:nth-child(4n) {
  margin-right: 0;
}

.course > div {
  padding: 2px 10px;
}

.tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 14px;
  border-radius: 0 10px 0 10px;
}

.cover-img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}
</style>
